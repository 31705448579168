import { useContext, useState } from "react";
import logo from "../../assets/images/sideLogo.png";
import globalMenuIcon from "../../assets/svgs/globalMenuIcon.svg";
import cancel from "../../assets/svgs/cancel.svg";
import Avatar from "react-avatar";
import "./globalMenu.css";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Button } from "../button/Button";
import { TENECE_SUPPORT_URL } from "../../utils/constants";
import { NewTag, Spinner, StudentProfileContext } from "..";
import useComponentVisible from "../../custom-hooks/useComponentIsVisible";
import { useApiGet, useApiPut } from "../../api/apiCall";
import {
	getAllRecentNotificationUrl,
	readNotificationUrl
} from "../../api/urls";
import { timeAgo } from "../../utils/formatDate";
import { Bell, LinkIcon } from "../../assets/svgs";
import { useQueryClient } from "react-query";
import ChristmasLogo from "../../assets/images/christmasLogo.png";
import { checkIfChristmasPeriod } from "../../utils/checkIfChristmasPeriod";

const GlobalMenu = ({
	title = "Components",
	openSide = () => {},
	setSignOutModal,
	userName,
	userRole,
	isLanding
}) => {
	const { push } = useHistory();
	const { pathname } = useLocation();
	const [menu, setMenu] = useState(false);
	const { ref, isComponentVisible, setIsComponentVisible } =
		useComponentVisible(false);

	const linkOptions = [
		{
			name: "Portal Landing",
			route: "/"
		},
		{
			name: "Admission Process Flow",
			route: "/admission_process_flow"
		},
		{
			name: "Portal Process",
			route: "/portal_process_flow"
		},
		{
			name: "Admission Status",
			route: "/prospective_students"
		},
	];

	const data = useContext(StudentProfileContext);

	const {
		data: recentNotifications,
		isLoading: isLoadingRecentNotifications
	} = useApiGet(getAllRecentNotificationUrl(), {
		enabled: !!userName,
		keepPreviousData: true,
		refetchOnWindowFocus: false
	});

	let recentUnreadNotifications = recentNotifications?.filter(
		({ isRead }) => !isRead
	)?.length;

	const { mutate, isLoading: isUpdating } = useApiPut({ retry: false });
	const queryClient = useQueryClient();

	const handleMarkNotificationAsRead = (id, isRead) => {
		if (isRead) return;

		const requestDet = {
			url: readNotificationUrl(id)
		}
		mutate(requestDet, {
			onSuccess: () => {
				queryClient.invalidateQueries({
					predicate: (query) =>
						query.queryKey.startsWith("Notification/")
				});
			},
			onError: ({ response }) => {
				throw response;
			}
		});
	};

	return (
		<section className="red-global-menu">
			<div className="d-flex align-items-center">
				{isLanding !== "unAuthenticated" ? (
					<button className="red-hamburger-menu" onClick={openSide}>
						<img src={globalMenuIcon} alt="open side menu" />
					</button>
				) : (
					<button
						className="red-hamburger-menu responsive-hamburger-menu"
						onClick={() => setMenu(true)}
					>
						<img src={globalMenuIcon} alt="open side menu" />
					</button>
				)}
				<div className="red-global-logo">
					{checkIfChristmasPeriod() ? (
						<>
							<img src={ChristmasLogo} alt="CCU logo" />
							<img src={ChristmasLogo} alt="CCU logo" />
						</>
					) : (
						<>
							<img src={logo} alt="CCU logo" />
							<img src={logo} alt="CCU logo" />
						</>
					)}
				</div>
				{isLanding === "unAuthenticated" ? (
					<div
						className={`red-current-module global-landing-page-link-container ${menu && `retract-navigation`
							}`}
					>
						<button
							onClick={() => setMenu(false)}
							className="cancel-button"
						>
							<img src={cancel} alt="" />
						</button>

						{linkOptions.map((link, i) => (
							<button
								key={i}
								className={`global-landing-page-links ${pathname === link.route &&
									"global-landing-page-links-active"
									}`}
								onClick={() => push(link.route)}
							>
								{link.name}
							</button>
						))}
					</div>
				) : (
					<div className="red-current-module">{title}</div>
				)}
			</div>
			{isLanding !== "unAuthenticated" ? (
				<div className="d-flex dropdown">
					{userName ? (
						<section
							className="gm-notification-container"
							ref={ref}
						>
							<button
								onClick={() =>
									setIsComponentVisible(!isComponentVisible)
								}
							>
								<Bell />
								{recentUnreadNotifications ? (
									<span className="gm-notification-breathing-light">
										{recentUnreadNotifications}
									</span>
								) : (
									""
								)}
							</button>
							<div
								ref={ref}
								className={`gm-notification-contents gm-notification-contents-style ${isComponentVisible ? "d-block" : "d-none"
									}`}
							>
								<div className="d-flex justify-content-between align-items-center border-bottom px-3 pt-3">
									<h3>
										Notifications(
										{recentNotifications?.length})
									</h3>
									<Link
										to="/notifications"
										className="gm-view-all"
									>
										View All Notifications
									</Link>
								</div>
								<div className="gm-notification-items pt-3">
									{isUpdating ||
										isLoadingRecentNotifications ? (
										<Spinner />
									) : (
										<ul>
											{recentNotifications?.map(
												(item, index) => (
													<li
														key={index}
														className={`py-2 border-bottom px-3 ${item?.isRead
															? "gm-notification-read"
															: ""
															}`}
														role="button"
														onClick={() =>
															handleMarkNotificationAsRead(
																item?.notificationId,
																item?.isRead
															)
														}
													>
														<div className="pr-4">
															<h4>
																{
																	item?.notificationType
																}
															</h4>
															<p className="my-1">
																{item?.message}
															</p>
															<p>
																{timeAgo(
																	item?.dateCreated
																)}
															</p>
														</div>
													</li>
												)
											)}
										</ul>
									)}
									<div className="py-4 text-center">
										That's all your notifications from the
										last 7 days.
									</div>
								</div>
							</div>
						</section>
					) : (
						""
					)}
					<section
						id="navDrop"
						data-toggle="dropdown"
						role="button"
						data-cy="open_avatat"
					>
						<Avatar
							name={userName ?? "Guest"}
							round
							size="32"
							className="global-avatar"
							maxInitials={2}
							src={data?.profileData?.personalData?.passport}
						/>
					</section>
					<section className="dropdown-menu res-role">
						<h3 className="res-user">Account</h3>
						<div className="res-wrapper">
							<div className=" res-roles w-100 d-flex justify-content-start align-items-center">
								<Avatar
									name={userName ?? "Guest"}
									round
									size="32"
									className="global-avatar mr-2"
									maxInitials={2}
									src={data?.profileData?.personalData?.passport}
								/>
								<div>
									<h3 className="res-profile-name">{userName?.toUpperCase() ?? "guest"}</h3>
									<h3 className="res-profile-detail mt-1">{data?.profileData?.programmeDetail?.level}</h3>
								</div>
							</div>

							<div className="res-roles mx-0 w-100">
								<div
									onClick={() => push("/profile")}
									className="mb-0 d-flex justify-content-between mt-3 ml-0"
								>
									Manage Profile
									<LinkIcon />
								</div>
							</div>
						</div>


						<section className="res-wrapper">
							<h3 className="res-user">Settings</h3>
							<div className="res-roles">
								<div className="d-flex align-items-center justify-content-between">
									<p className="mb-0 mx-0"
										onClick={() => push("/referral")}
									>
										Refer and Earn
									</p>
									<NewTag />
								</div>
							</div>
							<div className="res-roles">
								<div
									className="d-flex align-items-center"
									onClick={() => push("/change_password")}
								>
									<p className="mb-0 mx-0">Change Password</p>
								</div>
							</div>
							<div className="res-roles">
								<div onClick={() => window.open(`${TENECE_SUPPORT_URL}`, '_blank')}>
									<p className="mb-0 mx-0">Contact Support</p>
								</div>
							</div>
						</section>

						<section>
							<p
								className="mb-0 res-role-txt mt-3"
								onClick={() => setSignOutModal(true)}
							>
								Logout
							</p>
						</section>
					</section>
				</div>
			) : (
				<Button
					onClick={() => push("/login")}
					data-cy="default"
					buttonClass="primary"
					label="Login"
				/>
			)}
		</section>
	);
};

export { GlobalMenu };
